@import "colors";

.tableClass {
  background-color: $pink;
  width: 100%;
  height: 100vh;

  main {
    max-width: 900px;
    background-color: white;
    margin: auto;
    height: 100%;
    padding: 2rem;
    overflow-x: auto;

    > table {
      width: 100%;
      text-align: center;
      border-collapse: collapse;
      tr {
        background-color: whitesmoke;

        &:last-child {
          td:first-child {
            border-radius: 0 0 0 10px;
          }
          td:last-child {
            border-radius: 0 0 10px 0;
          }
        }
      }

      td,
      th {
        padding: 1rem;
      }

      th {
        background-color: $dark;
        color: white;
        &:first-child {
          border-radius: 10px 0 0 0;
        }
        &:last-child {
          border-radius: 0 10px 0 0;
        }
      }

      td {
        color: $lightdark;

        > img {
          width: 30px;
          height: 30px;
          border-radius: 100%;
        }

        > a {
          color: $red;
        }
        > button {
          border: none;
          cursor: pointer;
          margin: 0.25rem;
          color: $red;
        }
      }
    }
  }
}