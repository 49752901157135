@import "colors";
.cart {
  background-color: $pink;
  width: 100%;
  height: 100vh;

  > main {
    height: 100%;
    background-color: white;
    max-width: 900px;
    margin: auto;
    padding: 2rem;

    > article {
      padding: 2rem;
      > div {
        display: flex;
        justify-content: space-between;
        margin: 1rem 0;
      }

      > a {
        background-color: $red;
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        text-align: center;
        margin-top: 2rem;
        float: right;
      }
    }
  }
}

.cartItem {
  background-color: rgb(246, 246, 246);
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 5px;

  > div {
    display: flex;
    align-items: center;

    > h4 {
      font-weight: 200;
      letter-spacing: 2px;
    }

    > img {
      height: 50px;
    }

    > input {
      text-align: center;
      width: 2rem;
      height: 2rem;
      padding: 0.5rem;
      border: none;
      margin: 0.25rem;
      outline: none;
      border-radius: 5px;
      color: $red;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0%;
      }
    }

    > button {
      cursor: pointer;
      border: none;
      padding: 0.5rem;
      background-color: $dark;
      color: white;
      border-radius: 5px;
    }
  }
}

@media screen and (max-width: 600px) {
  .cart > main > article > a {
    float: none;
    display: block;
    width: 100%;
  }
}